// app/providers.js
'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: 'always', // or 'identified_only' to only send user profiles when user is identified
    autocapture: true, // Ensure autocapture is enabled if needed
    disable_session_recording: false, // Ensure session recording is enabled if needed
    identify_by: 'email', // Ensure identify_by is set to 'email'
    opt_in_site_apps: true, // Ensure opt_in_site_apps is set to true
  });
}
export function CSPostHogProvider({ children }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
