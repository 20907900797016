import { Lato } from 'next/font/google';

export const font = Lato({
  weight: ['300', '400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

export const fontFamily = font.style.fontFamily;

export const typography = {
  fontFamily,
  h1: {
    fontSize: '26px',
    fontWeight: 400,
    lineHeight: '32px',
  },
  h2: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '26px',
  },
  h3: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
};
