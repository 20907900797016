'use client';
import { breakpoints } from '@/styles/breakpoints';
import { components } from '@/styles/components';
import { typography } from '@/styles/typography';

import { CSSProperties } from 'react';
import { palette } from './palette';
import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    light: CSSProperties['color'];
    alt: CSSProperties['color'];
    white: CSSProperties['color'];
    stroke: CSSProperties['color'];
    gray: {
      ['50']: CSSProperties['color'];
      ['100']: CSSProperties['color'];
      ['150']: CSSProperties['color'];
      ['500']: CSSProperties['color'];
    };
    yellow: {
      ['100']: CSSProperties['color'];
    };
    green: {
      ['100']: CSSProperties['color'];
      ['800']: CSSProperties['color'];
    };
    red: {
      ['100']: CSSProperties['color'];
      ['800']: CSSProperties['color'];
    };
    severity: {
      low: CSSProperties['color'];
      lowFaded: CSSProperties['color'];
      medium: CSSProperties['color'];
      mediumFaded: CSSProperties['color'];
      high: CSSProperties['color'];
      highFaded: CSSProperties['color'];
      critical: CSSProperties['color'];
      criticalFaded: CSSProperties['color'];
    };
  }

  interface PaletteOptions {
    light?: CSSProperties['color'];
    alt?: CSSProperties['color'];
    white?: CSSProperties['color'];
    stroke?: CSSProperties['color'];
    gray?: {
      ['50']?: CSSProperties['color'];
      ['100']: CSSProperties['color'];
      ['150']: CSSProperties['color'];
      ['500']?: CSSProperties['color'];
    };
    yellow?: {
      ['100']?: CSSProperties['color'];
    };
    green?: {
      ['100']?: CSSProperties['color'];
      ['800']?: CSSProperties['color'];
    };
    red?: {
      ['100']?: CSSProperties['color'];
      ['800']?: CSSProperties['color'];
    };
    severity?: {
      low?: CSSProperties['color'];
      lowFaded?: CSSProperties['color'];
      medium?: CSSProperties['color'];
      mediumFaded?: CSSProperties['color'];
      high?: CSSProperties['color'];
      highFaded?: CSSProperties['color'];
      critical?: CSSProperties['color'];
      criticalFaded?: CSSProperties['color'];
    };
  }
}

export const theme = createTheme({
  palette,
  breakpoints,
  typography,
  components,
});
