import type { Components as MuiComponents } from '@mui/material';

import { fontFamily } from './typography';
import { palette } from './palette';

export const components: MuiComponents = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontFamily,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: '#FFF !important',
        borderRadius: '10px !important',
        border: '1px solid #D9D9D9',
        color: '#7D7D7D',
        overflow: 'hidden',
        '& .select-icon': {
          position: 'absolute',
          right: '7px',
          top: '12px',
        },
      },
      input: {
        backgroundColor: '#FFF',
      },
      inputAdornedStart: {
        backgroundColor: '#FFF',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        backgroundColor: '#FFF',
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
    },
    styleOverrides: {
      root: {
        backgroundColor: '#FFF',
        '.Mui-focused': {
          '&.MuiFormLabel-root': {
            color: 'inherit',
          },
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'standard',
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '12px !important',
      },
      select: {
        padding: '10px 14px',
        boxShadow: 'none',
        color: '#7D7D7D',
        overflow: 'hidden',
        position: 'relative',
        fontSize: '14px',
        'MuiOutlinedInput-notchedOutline': {
          border: '1px solid #D9D9D9',
        },
        '&.MuiInputBase-input': {
          paddingRight: 0,
          color: palette.gray['500'],
        },
        '& .select-value': {
          color: palette.primary.main,
        },
        '& .MuiTypography-root': {
          fontSize: '14px',
        },
      },
      icon: {
        fill: '#7D7D7D',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        padding: '9px',
        backgroundColor: palette.gray['150'],
        borderRadius: '20px',
        boxShadow: 'none',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        fontFamily,
        color: '#1E40AF',
        textDecorationColor: '#1E40AF',
        cursor: 'pointer',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        borderBottom: '1px solid #E9E8E9',
        '&:last-child': {
          borderBottom: 'none',
        },
        '&:hover': {
          backgroundColor: '#ffefe5',
        },
      },
      head: {
        backgroundColor: '#FAFBFB',
        '> span': {
          display: 'inline-block',
          width: '100%',
          color: '#7D7D7D',
        },
        '&:hover': {
          backgroundColor: '#FAFBFB',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        whiteSpace: 'nowrap',
        padding: '0 8px',
        height: '60px',
        textAlign: 'left',
        borderBottom: 'none',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        tableLayout: 'fixed',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: `${palette.primary.main} !important`,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: 'normal',
        color: 'grey',
        '&.Mui-selected': {
          color: palette.primary.main,
        },
        '&:hover': {
          color: palette.primary.main,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: '14px',
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: palette.secondary.main,
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        '& .MuiTypography-root': {
          fontSize: '14px',
        },
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        color: palette.primary.main,
      },
    },
  },
};
